import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import monkey from '../assets/imgs/monkey.png';
import hdw from '../assets/imgs/hdw.png';
import red from '../assets/imgs/red.png';
import http from '../config/http.js'
import '../assets/css/login.css';
import '../assets/css/weixin.css';
import '../assets/css/me.css';
import 'antd-mobile/dist/antd-mobile.css';

import {Row, Col, Card} from 'antd';
import Utils from "../util/utils";
import g from '../util/global.js'

let websocket = null;

class Login extends React.Component {
    constructor(props) {
        super(props);
        console.log(props)
        this.state = {
            bottom: false,
            InputValue: '',
            list: [],
            num: '',
            selectedTab: '',
            hidden: false,
            fullScreen: false,
            address: "",
            status: 0
        }
    }


    componentWillMount() {
        // alert(Utils.getLocalStorage("openid"))
        let query = {
            userId: JSON.parse(Utils.getLocalStorage("userInfo")).data.open_id
        }
        this.$postData("messageLog_selectList", query).then(res => {
            console.log(res)
            this.buttom(res) //跳转到底部
        })
       // this.connectWebSocket();
    }

    //建立WebSocket连接
    connectWebSocket() {
        //建立webSocket连接
        if ('WebSocket' in window) {
        } else {
            alert('该浏览器不支持websocket');
        }

        //websocket = new WebSocket('ws://xyjapi.xiaoyujia.com/myHandler/id='+Utils.getLocalStorage("openid"));
        websocket = new WebSocket('ws://120.77.45.52:9000/ws/' + JSON.parse(Utils.getLocalStorage("userInfo")).data.open_id);
        //websocket = new WebSocket('wss://xyjapi.xiaoyujia.com/ws/'+Utils.getLocalStorage("openid"));
        //打开webSokcet连接时，回调该函数
        websocket.onopen = function (res) {
            //console.log("onpen");
            //  var postValue = {};
            // postValue.id = Utils.getLocalStorage("openid")
            // postValue.message = "啦啦啦啦啦啦啦啦啦啦啦啦阿拉啦啦啦啦"
            // websocket.send(JSON.stringify(postValue));
        }

        let _this = this
        //关闭webSocket连接时，回调该函数
        websocket.onclose = function (res) {
            //关闭连接
            _this.connectWebSocket()
        }

        //接收信息
        websocket.onmessage = function (data) {
            // alert(data.data);
            let msg = JSON.parse(data.data)
            if (msg) {
                _this.state.list.push(msg)
                _this.setState({
                    list: _this.state.list
                })
                document.querySelector("#div").scrollIntoView(false)
            }
        }

        websocket.onerror = function (error) {
            //alert("login:"+error)
        };


    }

    //关闭连接
    closeWebSocket() {
        if (websocket != null) {
            websocket.close();
        }
    }

    handlePost = (InputValue) => {
        //alert(InputValue)
        if (InputValue != "") {
            // this.state.list.push({
            //     text: this.state.InputValue,
            // });
            // this.setState({
            //     bottom: false,
            //     list: this.state.list,
            //     id: this.state.id++
            // });
            this.answer(InputValue);
        } else {
            alert("请输入需求")
        }

    }
    answer = (InputValue) => {
        let list={
            userId: JSON.parse(Utils.getLocalStorage("userInfo")).data.open_id,
            content:InputValue,
            type:1
        }
        this.state.list.push(list)
        this.setState({
            list: this.state.list
        })
        let query = {
            userId: JSON.parse(Utils.getLocalStorage("userInfo")).data.open_id,
            content: InputValue
        }
        let _this = this
        this.$postData("yun_sendText", query).then(res => {
            console.log(res)
            g.goEasy.subscribe({
                channel: JSON.parse(Utils.getLocalStorage("userInfo")).data.open_id, //替换为您自己的channel
                onMessage: function (data) {
                    console.log(data.content)
                    let content = JSON.parse(data.content)
                        _this.state.list.push(content)
                        _this.setState({
                            list: _this.state.list
                        })
                    console.log(_this.state.list)
                        document.querySelector("#div").scrollIntoView(false)

                }
            });
        })

        // var postValue = {};
        // console.log(InputValue)
        // postValue.id = JSON.parse(Utils.getLocalStorage("userInfo")).data.open_id
        // console.log(InputValue)
        // postValue.content = InputValue
        // websocket.send(JSON.stringify(postValue));
    }

    buttom = (res) => {
        this.setState({
            list: res.data.data,
        });
        document.querySelector("#div").scrollIntoView(false)
    }

    render() {
        return (
            <div>
                <div className="div" id="div">
                    <div className="leftd" style={{paddingTop: "0.5cm"}}>
                        <span className="leftd_h">
                    <img src={monkey}/>
                    </span>
                        <div className="speech leftLogin" className="speech leftLogin">
                            亲，请问有什么可以帮您？
                        </div>
                    </div>


                    {this.state.list.map((text, index) => (
                        text.type === 1 ?
                            <div className="rightd" key={index}>
                    <span className="rightd_h">
                    <img
                        src={JSON.parse(Utils.getLocalStorage("userInfo")) == null ? monkey : JSON.parse(Utils.getLocalStorage("userInfo")).data.headimgurl}/>
                    </span>
                                <div className="speech right" className="speech right">
                                    {text.content}
                                </div>
                            </div> : text.type === 2 ?
                            <div className="leftd" key={index}>
                        <span className="leftd_h">
                    <img src={monkey}/>
                    </span>
                                <div className="speech leftLogin" className="speech leftLogin">
                                    <a href={text.content.indexOf("http") >= 0 ? text.content : "#"}> {text.content}</a>

                                </div>
                            </div> :
                            text.type === 3 ?
                                <Card key={index} style={{
                                    width: 95 + '%', marginTop: 20 + "%",
                                    marginLeft: 3 + "%", borderRadius: 5 + '%',
                                    marginBottom: 5 + "%",
                                }}>
                                    <Row id={text.id} key={index}>
                                        <Col span={4}>
                                            <div className="contentDiv1">
                                                <img src={monkey}/>
                                            </div>
                                        </Col>

                                        <Col span={8}>
                                            <div>
                                                <span>小羽佳</span>
                                                <br/>
                                                <span className="ziti">下午 14:50</span>
                                            </div>
                                        </Col>

                                        <Col>
                                            <div>
                                          <span className="xuqiu">
                                        需求：{text.content}
                                    </span>
                                            </div>
                                        </Col>

                                    </Row>
                                    <Row>
                                        <Col>
                                            <span className="baomu"> {text.content}</span>
                                        </Col>
                                    </Row>

                                    <Row className="km">
                                        <Col span={3}>
                                            <img src={red} className='red'/>
                                        </Col>
                                        <Col>
                                            <span className="dizi">{Utils.getLocalStorage("address")}</span>
                                        </Col>
                                    </Row>

                                    <Row className="km">
                                        <Col span={3}>
                                            {this.state.status == 0 ? "" : <img src={hdw} className='hdw'/>}
                                        </Col>
                                        <Col span={12}>
                                <span id="juli">
                                         {this.state.status != 0 ? "3 km" : ""}
                                </span>
                                        </Col>
                                        <Col span={5}>
                                            {this.state.status != 0 ?
                                                <Link to="/order" className="button1">联系Ta</Link> : ""}

                                        </Col>
                                        <Col span={4}>
                                            <div id="circle" onClick={() => {
                                                this.props.history.push("/time")

                                            }}>
                                                {this.state.status != 0 ? "已派单" : "预约"}
                                            </div>
                                        </Col>
                                        <span id="button">
                                    </span>
                                    </Row>

                                </Card> :
                                <div key={index}></div>
                    ))}

                </div>
            </div>

        )
    }
}

export default Login



