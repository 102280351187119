import React, {Component} from 'react';
import './App.css';
import 'antd/dist/antd.css';
import Utils from "./util/utils";


class App extends React.Component {
    constructor(props) {
        console.log(props)
        super(props);
    }

    componentWillMount() {
        // Utils.setLocalStorage("openid", "oayJ305dvx67Fq4__uiVy9aHXWMs")
        // let userInfo='{"data":{"open_id":"oayJ305dvx67Fq4__uiVy9aHXWMs","sex":"男","mobile":"15270580103","property_wx_id":"haina_developer","property_id":"98181b6e4c7f954f79ce13f67583ce4f","property_name":"海纳社区开发者自测","tags":[],"name":"邓佳明","nickname":"我已仿佛会飞","headimgurl":"http://thirdwx.qlogo.cn/mmopen/lpHDr05YrITXd7TdRKdxPbqfBZP1dVsPW016dpvgErB9iap9jLK2dBFBVibFWefQibBdsmicpQOib1AWOG7KSLJJljFO7XkJt6rZ5/132","houses":[{"address_ids":"-99025-594034-3926403-2463161-","community_id":"9799baea5f6b10630ef97a6d6d0d752b","address":"2栋/1单元/1层/1","user_type":1,"province":"","city":"","community_name":"小羽佳测试小区","district":"","community_address":"","is_default":1},{"address_ids":"-99025-594036-3926409-2463167-","community_id":"9799baea5f6b10630ef97a6d6d0d752b","address":"2栋/2单元/2层/1","user_type":1,"province":"","city":"","community_name":"小羽佳测试小区","district":"","community_address":"","is_default":0}],"union_id":"05d1122c8208f0fb20a28d1bad3a2725","resident_id":"dfc402117314e62aa1ec9aba2fedac6c"},"retmsg":"success","retcode":0}';
        // Utils.setSessionStorage("userInfo",userInfo)
        // this.props.history.push("/home")
        let code = this.getUrlParam().resident_code;
        // //alert(code)
        if (code) {
            //alert("保存");
            this.getUserList(code)
        } else {
            //alert("海纳进入");
            //         // window.location.href = "https://www.haina.com/m/98181b6e4c7f954f79ce13f67583ce4f/cp/agrkR4oAfwzo8QE66B"
            window.location.href = "https://www.haina.com/m/98181b6e4c7f954f79ce13f67583ce4f/cp/agPBrewRu2nXx7R89Z"
        }
    }

    //this.props.history.push("/home")
    //alert(this.getUrlParam().resident_code)
    //     if (this.getUrlParam().resident_code) {
    //         //alert("保存");
    //         Utils.setLocalStorage("residentCode", this.getUrlParam().resident_code); //授权成功resident_code存起来
    //         this.getUserList(this.getUrlParam().resident_code)
    //         this.getGrant();
    //     } else if (!Utils.getLocalStorage("residentCode")) {
    //        // alert("海纳进入");
    //         // window.location.href = "https://www.haina.com/m/98181b6e4c7f954f79ce13f67583ce4f/cp/agrkR4oAfwzo8QE66B"
    //         window.location.href = "https://www.haina.com/m/98181b6e4c7f954f79ce13f67583ce4f/cp/agPBrewRu2nXx7R89Z"
    //     }else {
    //         this.getGrant();
    //     }
    // }

    getUserList(code) {
        this.$getData("getUserList", {code: code}).then(mes => {
            console.log(mes)
            let res = JSON.parse(mes.data.data);
            //this.props.history.push("/home")
           // Utils.setLocalStorage("openid", res.data.open_id)
            Utils.setLocalStorage("userInfo", mes.data.data); //授权成功把用户信息存起来
            let address = ""
            for (let i = 0; i < res.data.houses.length; i++) {
                let re = res.data.houses[i];
                address += re.community_name
                address += re.address
                Utils.setLocalStorage("address", address);
                this.props.history.push("/home")
                break
            }
        })
    }

    getGrant() {
        let a = true;
        // Utils.delLocalStorage("token");
        // let userInfo='{"sex":null,"nickname":"飞","openid":null,"unionid":null,"phone":"15270580103","token":"d9a99a013dd9476683794fb2c3a907a1","account":"15270580103","headImg":"https://statics.xiaoyujia.com/member/headPortrait/2019/07/09/09590248864_104x144.jpg","grade":"1","failureTime":"2036-08-20T00:52:17.485+0000","memberId":465094,"hasUseCard":false}';
        //  Utils.setSessionStorage("userInfo",userInfo)
        console.log("进入微信授权");
        Utils.delLocalStorage("userInfo");
        if (Utils.getLocalStorage("userInfo") && JSON.parse(Utils.getLocalStorage("userInfo")).phone) {
            console.log("判断进入6565");
            this.props.history.push("/home")
        } else {
            // 若不存在code，则请求授权的接口，拿到授权的链接、打开、确定授权、获取code
            // 存在code，则去请求后台接口拿openid。。。。。
            let code = this.getUrlParam().code;
            Utils.setLocalStorage("code", code)
            console.log(code)
            if (code) {
                this.props.history.push("/home")
                //this.getWeixinOpenid()
            } else {
                this.getWeixinCode();
            }
        }
    }

    getUrlParam(url) {
        if (typeof url === "undefined") {
            url = window.location.href;
        }
        if (url.indexOf("?") === -1) {
            return {};
        }
        url = decodeURIComponent(url);
        let paraString = url
                .substring(url.lastIndexOf("?") + 1, url.length)
                .split("&"),
            paraObj = {};
        let i, j;
        for (i = 0; (j = paraString[i]); i++) {
            let key = j.substring(0, j.indexOf("=")).toLowerCase();
            let value = j.substring(j.indexOf("=") + 1, j.length);
            paraObj[key] = value;
        }
        return paraObj;
    }

    getCode(appids) {
        let appid = appids;
        let href = window.location.href;
        let redirect_uri = "https://xyj.xiaoyujia.com";  //本地
        //let redirect_uri = "http://localhost:3000";  //本地
        let url =
            "https://open.weixin.qq.com/connect/oauth2/authorize?appid=" +
            appid +
            "&redirect_uri=" +
            redirect_uri +
            "&response_type=code&scope=snsapi_userinfo&state=abcdefghigklmnopqrstuvwxyz#wechat_redirect";
        console.log(url);
        window.location.href = url;
    }

    getWeixinCode() {
        let returnUrl = "http://open.xiaoyujia.com/index?returnUrl=https://xyj.xiaoyujia.com";
        //let returnUrl = "http://open.xiaoyujia.com/index?returnUrl=http://localhost:3000";
        this.$getData("openLogin", {returnUrl: returnUrl}).then(res => {
            console.log(res)
            if (res.data.meta.state == 200) {
                console.log(res)
                window.location.href = res.data.data
            } else {
                alert(res.data.meta.msg)
            }
        })
    }

    getWeixinOpenid() {
        let code = this.getUrlParam().code
        console.log(code);
        this.$getData("getOpenId", {code: code}).then(res => {
            console.log(res)
            if (res.data.data) {
                this.login(res.data.data.openid)

                // } else {
                //     console.log("注册页");
                //     this.$router.push({path: "/register"});
                // }
            }
        })
    }


    login(openId) {
        console.log(openId)
        this.$getData("login", {openId: openId}).then(res => {
            console.log(res)
            if (res.data.status == 200) {
                Utils.setLocalStorage("userInfo", JSON.stringify(res.data.data)); //授权成功把用户信息存起来
                //判断用户是否注册，未注册跳转注册页 500代表未注册
                console.log('判断进入222')
                console.log(Utils.getLocalStorage("userInfo"))
                this.props.history.push("/home")
            }
        });
    }

    render() {
        return (
            <div></div>
        )
    }
}

export default App;
