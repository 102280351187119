import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';
import monkey from "../assets/imgs/monkey.png";
import {Paper, Typography} from "./Login";
import avatar from "../assets/imgs/xiaoxi.png";
import fabu from "../assets/imgs/22.png";
import me from "../assets/imgs/me.png";

const useStyles = makeStyles({
    list: {
        width: 250,
    },
    fullList: {
        width: 'auto',
    },
});

export default class Text {
    render() {
        return (
            <div>
                <div className="wc__footTool-panel">
                    <div className="wc__editor-panel wc__borT flexbox">
                        <div className="wrap-editor flex1">
                            <div className="editor J__wcEditor" contentEditable="true"></div>
                        </div>
                        <i className="btn btn-emotion"></i>
                        <i className="btn btn-choose"></i>
                        <button className="btn-submit J__wchatSubmit">发送</button>
                    </div>


                    <div className="wc__choose-panel wc__borT" style="display: none;">
                        <div className="wrap-emotion" style="display: none;">
                            <div className="emotion__cells flexbox flex__direction-column">
                                <div className="emotion__cells-swiper flex1" id="J__swiperEmotion">
                                    <div className="swiper-container">
                                        <div className="swiper-wrapper"></div>
                                        <div className="pagination-emotion"></div>
                                    </div>
                                </div>
                                <div className="emotion__cells-footer" id="J__emotionFootTab">
                                    <ul className="clearfix">
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className="wrap-choose" style="display: none;">
                            <div className="choose__cells">
                                <ul className="clearfix">
                                    <li><a className="J__wchatZp" href="javascript:;"><span className="img"><img
                                        src="img/wchat/icon__choose-zp.png"/><input type="file"
                                                                                    accept="image/*"/></span><em>照片</em></a>
                                    </li>
                                    <li><a className="J__wchatSp" href="javascript:;"><span className="img"><img
                                        src="img/wchat/icon__choose-sp.png"/><input type="file"
                                                                                    accept="video/*"/></span><em>视频</em></a>
                                    </li>
                                    <li><a className="J__wchatHb" href="javascript:;"><span className="img"><img
                                        src="img/wchat/icon__choose-hb.png"/></span><em>红包</em></a></li>
                                    <li><a className="J__wchatSc" href="javascript:;"><span className="img"><img
                                        src="img/wchat/icon__choose-sc.png"/></span><em>我的收藏</em></a></li>
                                    <li><a className="J__wchatWj" href="javascript:;"><span className="img"><img
                                        src="img/wchat/icon__choose-wj.png"/></span><em>文件</em></a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}