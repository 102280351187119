export default {
    //需求改写
    openLogin:"https://open.xiaoyujia.com/open/authorize",
    getOpenId:"https://open.xiaoyujia.com/open/userInfo",
    wxUrl:"https://inside.xiaoyujia.com/api/Other/getShareParameter",

    login:"api/account/login",

    //海纳

    getUserList:"api/haina/getUserList",

    messageLog_selectList:"api/messageLog/selectList",
    order_getById: "api/order/getById",
    order_list: "api/order/list",


    //云客服
    yun_sendText: "api/yun/sendText",
    yun_MaxDate: "api/yun/getByMaxDate",
    upload:"api/speech/getValues",
    //消息列表
    messageLogList:"api/messageLogList/selectList",
}