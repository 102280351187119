import axios from 'axios'
import qs from 'qs'
import React, {Component} from 'react';
import api from "./api";

Component.prototype.$axios = axios  //将axios挂载到Component上，以供全局使用
   axios.defaults.baseURL = "https://xyjapi.xiaoyujia.com";
//axios.defaults.baseURL = "http://localhost:9000";
//axios.defaults.headers.post['Content-Type'] = 'application/json;charset=UTF-8';
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8';

// 添加请求拦截器
axios.interceptors.request.use(function (res) {
    // 在发送请求之前做些什么
    console.log(res)
    return res;
}, function (error) {
    // 对请求错误做些什么
    return Promise.reject(error);
});

// 添加响应拦截器
axios.interceptors.response.use(function (response) {
    // 对响应数据做点什么
    return response;
}, function (error) {
    // 对响应错误做点什么
    return Promise.reject(error);
});


let getData = function (url, params) {
    return axios.get(api[url], {
        params
    });
}
let postData = function (url, data, config = {}) {
    return axios.post(api[url], data, config);
}
Component.prototype.$postData = postData;
Component.prototype.$getData = getData;


const httpServer = (opts) => {
    /* console.log("opts -------");
     console.log(opts);*/
    if (!opts.method) {
        opts.method = 'get';
    }
    let params = { //公共参数
    };

    let cookie = null;
    var arr, reg = new RegExp("(^| )setCookie=([^;]*)(;|$)");
    if (arr = document.cookie.match(reg)) {
        cookie = (arr[2]);
    }
    //是否为get的请求
    let isGet = opts.method == 'get';

    let httpDefaultOpts = { //http默认配置
        method: opts.method,
        url: opts.url,
        params: Object.assign(params, opts.data),
        data: qs.stringify(Object.assign(params, opts.data)),
        headers: isGet ? {
            'X-Requested-With': 'XMLHttpRequest',
            "Accept": "application/json",
            "Content-Type": "application/json; charset=UTF-8",
            "Cache-Control": "no-cache",
            "Pragma": "no-cache",
            //"yxTicket": cookie
        } : {
            'X-Requested-With': 'XMLHttpRequest',
            'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
            //"yxTicket": cookie
        }
    };

    if (opts.method == 'get') {
        delete httpDefaultOpts.data
    } else {
        delete httpDefaultOpts.params
    }

    let promise = new Promise(function () {
        axios(httpDefaultOpts).then(
            (res) => {
                if (res.status == 200) {
                    opts.onSuccess(res);
                } else {
                    if (opts.onError) {
                        opts.onError(res);
                    } else {
                        alert(res.msg);
                    }
                }
            }
        ).catch(
            (error) => {
                if (opts.onReject) {
                    opts.onReject(error);
                } else {
                    alert('系统异常，请稍后重试');
                }
            }
        )

    });
    return promise
};


export default {
    httpServer, getData, postData
}
