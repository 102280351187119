import React, {Component} from 'react';
import {List, Calendar, Flex , NavBar} from 'antd-mobile';
import rl from "../assets/imgs/time/ll.png";
import '../assets/css/time.css';
import qb from '../assets/imgs/me/qb.png';
import gou from '../assets/imgs/time/gou.png';



const extra = {
    '2017/07/15': {info: 'Disable', disable: true},
};
Object.keys(extra).forEach((key) => {
    const info = extra[key];
    const date = new Date(key);
    if (!Number.isNaN(+date) && !extra[+date]) {
        extra[+date] = info;
    }
});

const now = new Date();
extra[+new Date(now.getFullYear(), now.getMonth(), now.getDate() + 5)] = {info: '可预约', disable: true};
extra[+new Date(now.getFullYear(), now.getMonth(), now.getDate() + 6)] = {info: '可预约', disable: true};
extra[+new Date(now.getFullYear(), now.getMonth(), now.getDate() + 7)] = {info: '可预约', disable: true};
extra[+new Date(now.getFullYear(), now.getMonth(), now.getDate() + 8)] = {info: '可预约', disable: true};

const Item = List.Item;


class Time extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
        };
    }

    componentWillMount() {
    }

    onSelectHasDisableDate = (dates) => {
        console.warn('onSelectHasDisableDate', dates);
    }

    onConfirm = (startTime, endTime) => {
        document.getElementsByTagName('body')[0].style.overflowY = this.originbodyScrollY;
        this.setState({
            show: false,
            startTime,
            endTime,
        });
    }

    onCancel = () => {
        document.getElementsByTagName('body')[0].style.overflowY = this.originbodyScrollY;
        this.setState({
            show: false,
            startTime: undefined,
            endTime: undefined,
        });
    }

    getDateExtra = date => extra[+date];
    renderBtn = () => {
        this.setState({
            show: true
        })
    }

    render() {
        return (

            <div>
                <NavBar
                    mode="light">选择时间
                </NavBar>
                <Calendar
                    type='one'
                    visible={this.state.show}
                    onCancel={this.onCancel}
                    onConfirm={this.onConfirm}
                    onSelectHasDisableDate={this.onSelectHasDisableDate}
                    getDateExtra={this.getDateExtra}
                    defaultDate={now}
                    minDate={new Date()}
                    maxDate={new Date(+now + 31536000000)}
                />
                <div>
                    <div style={{backgroundColor: "#FFD666", height: "78px"}}>
                        <Flex justify="between">
                            <div className="rl" style={{marginLeft: "0.5rem",backgroundColor:" #fff",width: "50px",height: "50px"}}>
                                <span>周四</span>
                                <p>11/21</p>
                            </div>
                            <div className="rl">
                                <span>周四</span>
                                <p>11/21</p>
                            </div>
                            <div className="rl">
                                <span>周四</span>
                                <p>11/21</p>
                            </div>
                            <div className="rl">
                                <span>周四</span>
                                <p>11/21</p>
                            </div>
                            <div className="rl">
                                <span>周四</span>
                                <p>11/21</p>
                            </div>
                            <div>
                                <img style={{width: "60px"}} src={rl} onClick={this.renderBtn}/>
                            </div>
                        </Flex>

                    </div>
                    <div style={{backgroundColor: "#FFF", height: "80px"}}>
                        <Flex justify="center">
                            <div  className="timeSj">
                                <Flex justify="center">
                                    <div className="timejiage">08:00</div>
                                    <div className="timejiage">价格:500</div>
                                </Flex>

                            </div>
                            <div  className="timeSj" style={{backgroundColor:"#fff", border: "1px solid"}}>
                                <Flex justify="center">
                                    <div className="timejiage">08:00</div>
                                    <div className="timejiage">价格:500</div>
                                </Flex>
                            </div>
                        </Flex>
                    </div>
                </div>

                <div style={{marginTop:"15px"}}>
                    <List>
                        <Item activeStyle={{backgroundColor: "#e3e3e3"}}
                              extra={
                                  <div>
                                      <img  style={{marginLeft:"4rem",width: "25px",height: "25px"}} src={gou}/>
                                  </div>
                              } align="top"
                              thumb={<img style={{ width: "25px",height: "25px"}} src={qb} />}
                              multipleLine>
                            余额
                        </Item>
                    </List>
                </div>

                <div className="timeQue" onClick={() => {
                    this.props.history.push("/more")
                }}>

                </div>
            </div>
        )
    }
}

export default Time