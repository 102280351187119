import React from "react";
import {List} from "antd-mobile";
import {Card, WingBlank, WhiteSpace} from 'antd-mobile';
import {Col, Row} from "antd";
import monkey from "../assets/imgs/monkey.png";
import '../assets/css/me.css';
import huiyuanka from '../assets/imgs/me/huiyuanka.png';
import jt from '../assets/imgs/me/jt.png';
import jz from '../assets/imgs/me/jiazhengfuwu.png';
import mj from '../assets/imgs/me/menjinguanli.png';
import tc from '../assets/imgs/me/tingche.png';
import qb from '../assets/imgs/me/qb.png';
import wy from '../assets/imgs/me/wy.png';
import pj from '../assets/imgs/me/pj.png';
import yy from '../assets/imgs/me/yy.png';
import Utils from "../util/utils";

const Item = List.Item;
const Brief = Item.Brief;


class Me extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            account: ""
        }
    }

    componentWillMount() {
    }

    go = (event) => {
        window.history.go(-1)
    }

    render() {
        return (
            <div  style={{backgroundColor: "#fff",paddingTop:"1cm"}}>
                <div style={{backgroundColor: "#FFFFFF", height: "110px"}}>
                    <Row>
                        <Col span={5}>
                            <div className="me">
                                <img style={{width:"50px",height:"50px"}}
                                     src={JSON.parse(Utils.getLocalStorage("userInfo"))==null?monkey:JSON.parse(Utils.getLocalStorage("userInfo")).data.headimgurl}/>
                            </div>
                        </Col>

                        <Col span={19}>
                            <div style={{marginTop: "9%"}}>
                                <div>
                                    <span style={JSON.parse(Utils.getLocalStorage("userInfo"))==null?{color:"red"}:{}}>
                                        {JSON.parse(Utils.getLocalStorage("userInfo"))==null?"未登录":JSON.parse(Utils.getLocalStorage("userInfo")).data.name}
                                        </span>
                                </div>
                                <div>
                                    <span>{JSON.parse(Utils.getLocalStorage("userInfo"))==null?"":JSON.parse(Utils.getLocalStorage("userInfo")).data.mobile}</span>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>


                <div style={{backgroundColor: "#DDF3FE", marginLeft: " 5%", marginRight: "5%",height: "55px"}}>
                    <Row>
                        <Col span={3}>
                            <div className="ka">
                                <img src={huiyuanka} className="mine_icon"/>
                            </div>
                        </Col>
                        <Col span={16}>
                            <div className="sj">未升级会员卡</div>
                        </Col>
                        <Col span={3}>
                            <div style={{marginLeft: "20%", marginTop: "40%"}}>升级</div>
                        </Col>
                        <Col span={2}>
                            <img src={jt} className="mine_icon1"/>
                        </Col>
                    </Row>
                </div>

                <div style={{backgroundColor: "#f0f2f5"}}>
                    <div style={{backgroundColor: ""}}>

                        <WingBlank size="lg">
                            <WhiteSpace size="lg"/>
                            <Card>
                                <div style={{borderBottom: "1px inset", height: "40px"}}>
                                    <Row>
                                        <Col span={3}>
                                            <div className="topDiv"><img className="zpMe" src={qb}/></div>
                                        </Col>
                                        <Col span={16}>
                                            <div className="textDiv">
                                                钱包
                                            </div>
                                        </Col>
                                        <Col span={3}>
                                            <div className="textDiv1">
                                                50元
                                            </div>
                                        </Col>
                                        <Col span={2}>
                                            <div className="topDiv1"><img src={jt} className="mine_icon3"/></div>
                                        </Col>
                                    </Row>
                                </div>
                                <div style={{borderBottom: "1px inset", height: "40px"}}>
                                    <Row>
                                        <Col span={3}>
                                            <div className="topDiv"><img className="zpMe" src={jz}/></div>
                                        </Col>
                                        <Col span={16}>
                                            <div className="textDiv">
                                                家政服务
                                            </div>
                                        </Col>
                                        <Col span={5}>
                                            <div className="topDiv1"><img src={jt} className="mine_icon2"/></div>
                                        </Col>
                                    </Row>
                                </div>
                                <div style={{borderBottom: "1px inset", height: "40px"}}>
                                    <Row>
                                        <Col span={3}>
                                            <div className="topDiv"><img className="zpMe" src={mj}/></div>
                                        </Col>
                                        <Col span={16}>
                                            <div className="textDiv">
                                                门禁管理
                                            </div>
                                        </Col>
                                        <Col span={5}>
                                            <div className="topDiv1"><img src={jt} className="mine_icon2"/></div>
                                        </Col>
                                    </Row>
                                </div>
                                <div style={{height: "35px"}}>
                                    <Row>
                                        <Col span={3}>
                                            <div className="topDiv"><img className="zpMe" src={wy}/></div>
                                        </Col>
                                        <Col span={16}>
                                            <div className="textDiv">
                                                物业缴费
                                            </div>
                                        </Col>
                                        <Col span={5}>
                                            <div className="topDiv1"><img src={jt} className="mine_icon2"/></div>
                                        </Col>
                                    </Row>
                                </div>

                            </Card>
                            <WhiteSpace size="lg"/>
                        </WingBlank>
                    </div>

                    <div>
                        <WingBlank size="lg">
                            <WhiteSpace size="lg"/>
                            <Card>
                                <Row>
                                    <Col>
                                        <div style={{
                                            fontWeight: "bold",
                                            marginLeft: "5%",
                                            height: "35px",
                                            marginTop: "2%"
                                        }}>我的预约
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={7}>
                                        <div style={{fontWeight: "bold", marginLeft: "1rem"}}>
                                            <img src={yy} className="pjMe"/>
                                        </div>
                                        <span style={{marginLeft: "0.78rem"}}>已预约</span>
                                    </Col>
                                    <Col span={8}>
                                        <div style={{fontWeight: "bold", marginLeft: "0.38rem"}}>
                                            <img src={pj} className="pjMe"/>
                                        </div>
                                        <span style={{marginLeft: "0.18rem"}}> 待评价</span>
                                    </Col>
                                </Row>
                            </Card>
                            <WhiteSpace size="lg"/>
                        </WingBlank>


                    </div>
                </div>


            </div>
        )
    }
}

export default Me