import React from 'react';
// 配置路由规则
import { Router,Route,NavLink,Switch,Redirect } from 'dva/router';
import Login from '../components/Login.js';
import Text from '../components/Text.js';
import Order from '../components/Order.js';
import Me from '../components/Me.js';
import News from '../components/News.js';
import Home from '../components/Home.js';
import Time from '../components/Time.js';
import More from '../components/More.js';
import App from '../App.js';


let fn = function ({ history,app }) {
    return (
        <Router history={history}>
               <Switch>
                   <Route path="/" exact component={App}/>
                   <Route path="/home" exact component={Home}/>
                   <Route path="/login" exact component={Login}/>
                   <Route path="/text" exact component={Text}/>
                   <Route path="/order" exact component={Order}/>
                   <Route path="/me" exact component={Me}/>
                   <Route path="/news" exact component={News}/>
                   <Route path="/time" exact component={Time}/>
                   <Route path="/more" exact component={More}/>
               </Switch>
        </Router>
    )
}

export default fn;