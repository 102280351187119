import React from "react";
import {Icon, NavBar,Accordion} from "antd-mobile";
import {Row, Col} from 'antd';
import {Input} from 'antd';
import {Card } from 'antd-mobile';
import yuying from '../assets/imgs/detail/yuying.png';
import biaoqing from '../assets/imgs/detail/biaoqing.png';
import jia from '../assets/imgs/detail/jia.png';
import time from '../assets/imgs/detail/time.png';
import pj from '../assets/imgs/detail/pj.png';
import dw from '../assets/imgs/detail/dw.png';
import hb from '../assets/imgs/detail/hb.png';
import xl from '../assets/imgs/detail/xl.png';

import '../assets/css/order.css';
import '../assets/css/login.css';
import monkey from "../assets/imgs/monkey.png";


export default class Order extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isShow: false,
            isDetail:false,
        }
    }

    componentWillMount() {
    }

    go = (event) => {
        console.log("fgh")
        window.history.go(-1)
    }
    show = (event) => {
        if (this.state.isShow) {
            this.setState({
                isShow: false,
            })
        } else {
            this.setState({
                isShow: true
            })
        }
    }
    zp = (event) => {
        //获取浏览器的userAgent,并转化为小写
        let ua = navigator.userAgent.toLowerCase();
        console.log(ua)
        //判断是否是苹果手机，是则是true
        let isIos = (ua.indexOf('iphone') != -1) || (ua.indexOf('ipad') != -1);
        if (isIos) {
            console.log(isIos)
            document.getElementById("#aaa").removeAttribute("capture");

        }
        ;
    }
    detail = (event) => {
        if (this.state.isDetail) {
            this.setState({
                isDetail: false,
            })
        } else {
            this.setState({
                isDetail: true
            })
        }
    }

    render() {
        return (
            <div>
                <div className="top">
                    <NavBar onLeftClick={this.go}
                            mode="light"
                            icon={<Icon type="left"/>}
                            rightContent={[
                                <Icon key="1" type="ellipsis"/>,
                            ]}
                    >订单详情</NavBar>
                    <div style={{width:"100%",backgroundColor: "#f0f2f5",height:"2px"}}></div>
                    <div style={{backgroundColor: "#FFFFFF", height: "20px"}}>
                        <Row>
                            <Col span={4}>
                                <div style={{width: "18px", height: "18px", marginLeft: "40%"}}>
                                    <img src={time}></img>
                                </div>
                            </Col>
                            <Col span={20}>
                                <div style={{fontSize: "12px"}}>
                                    距离订单结束时间23:59:59
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>

                <div style={{width:"100%",height:"100px"}}>

                </div>

                <div className="card">
                    <Card>
                        <Card.Body>
                            <Row>
                                <Col span={4}>
                                    <div className="contentDiv1">
                                        <img src={monkey}/>
                                    </div>
                                </Col>

                                <Col span={16}>
                                    <div>
                                        <span>小羽佳</span>
                                        <span className="xuqiu">订单需求</span>
                                    </div>
                                </Col>
                                <Col span={4}>
                                    <div>
                                        <span className="xuqiu">需求</span>
                                    </div>
                                </Col>

                            </Row>
                            <Row>
                                <Col>
                                    <span className="baomu"> 我家要找个保洁</span>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </div>

                <div className="card">
                    <Card style={{borderRadius: "5%"}}>
                        <Card.Body>
                            <Row>
                                <Col span={4}>
                                    <div className="contentDiv1">
                                        <img src={monkey}/>
                                    </div>
                                </Col>

                                <Col span={11}>
                                    <div>
                                        <span style={{fontSize: " 10px"}}>金牌保洁 王女士</span>
                                        <br/>
                                        <span style={{fontSize: " 8px"}}>已服务306单</span>
                                    </div>
                                </Col>
                                <Col span={9}>
                                    <div style={{width: "100px", height: "24px"}}>
                                        <img src={pj}/>
                                    </div>
                                    <div style={{float: "left"}}>
                                        <img src={dw} style={{width: "20px", height: "20px"}}/>
                                    </div>
                                    <div>
                                        <span style={{fontSize: " 10px", marginLeft: "15%"}}>距离您3km</span>
                                    </div>

                                </Col>

                            </Row>
                            <Row>
                                <Col>
                                <div>
                                    <div  onClick={this.detail} style={{ fontSize: "8px",
                                        marginLeft: "30%",float:"left"}}>点击查看详情实时跟进位置</div>
                                    <div style={{float:"left"}} onClick={this.detail}>
                                        <img  style={{width:"8px",height:"5px",marginTop:"20%"}} src={xl}/></div>
                                            </div>

                                </Col>
                            </Row>
                            <Row style={this.state.isDetail == false ? {display: "none"}:{display: "block"}}>
                                <Col>
                                    <div>告诉你一个秘密，我是无敌的。</div>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </div>

                <div style={{bottom: 0, position: "fixed", width: 100 + "%", zIndex: 98, backgroundColor: "#FFFFFF",}}>
                    <div style={{height: "60px"}}>
                        <Row>
                            <Col span={3}>
                                <div style={{marginTop: "40%", marginLeft: "32%", width: "20px", height: "26px"}}>
                                    <img src={yuying}/>
                                </div>
                            </Col>
                            <Col span={16}>
                                <Input size="large" style={{marginTop: "4%"}}/>
                            </Col>
                            <Col span={2}>
                                <div style={{marginTop: "55%", marginLeft: "24%", width: "25px", height: "25px"}}><img
                                    src={biaoqing}/></div>
                            </Col>
                            <Col span={2}>
                                <div style={{width: "25px", height: "25px", marginTop: "55%", marginLeft: "45%"}}><img
                                    onClick={this.show} src={jia}/></div>
                            </Col>
                        </Row>
                    </div>
                    <div style={this.state.isShow == false ? {display: "none"} : {
                        display: "block",
                        marginTop: "8%",
                        height: "130px"
                    }}>
                        <Row type="flex" justify="space-around">
                            <Col span={8}>
                                <div className="zp" onClick={this.zp}>
                                    <input style={{opacity: 0}} type="file" name="cover" accept="image/*"
                                           capture="camera" multiple/>
                                </div>
                                <div style={{marginLeft: "38%"}}>
                                    照片
                                </div>
                            </Col>
                            <Col span={8}>
                                <div className="xj">
                                    <input style={{opacity: 0}} type="file" name="cover" accept="image/*"
                                           capture="camera" multiple/>
                                </div>
                                <div style={{marginLeft: "37%"}}>
                                    拍摄
                                </div>
                            </Col>
                            <Col span={8}>
                                <div style={{width: "50px", height: "50px", marginLeft: "30%"}}>
                                    <img src={hb}/>
                                </div>
                                <div style={{marginLeft: "38%"}}>
                                    打赏
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>

            </div>
        )
    }
}