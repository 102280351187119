import React, {Component} from 'react';
import avatar from "../assets/imgs/xiaoxi.png";
import fabu from "../assets/imgs/fabu.png";
import me from "../assets/imgs/me.png";
import {Icon, NavBar, TabBar, Popover, Toast} from 'antd-mobile';
import {Col, Divider, Row} from "antd";
import yuyin from "../assets/imgs/yuyin.png";
import biaoqing from "../assets/imgs/biaoqing.png";
import yynn from "../assets/imgs/yynn.png";
import dian from "../assets/imgs/dian.png";
import gif from "../assets/imgs/gif.gif";
import qx from "../assets/imgs/qx.png";
import fs from "../assets/imgs/fs.png";
import {Input} from 'antd';
import {Drawer} from "@material-ui/core";
import News from './News.js'
import Me from './Me.js'
import Login from './Login.js'
import Recorder from 'js-audio-recorder'
import http from '../config/http.js'

import $ from 'jquery'


import '../assets/css/home.css';
import Utils from "../util/utils";

const {TextArea} = Input;
const recorder = new Recorder({
    sampleBits: 16,         // 采样位数，支持 8 或 16，默认是16
    sampleRate: 16000,      // 采样率，支持 11025、16000、22050、24000、44100、48000，根据浏览器默认值，我的chrome是48000
    numChannels: 1,         // 声道，支持 1 或 2， 默认是1
    compiling: false,       // 是否边录边转换，默认是false
});
const Item = Popover.Item;
const myImg = src => <img src={`https://gw.alipayobjects.com/zos/rmsportal/${src}.svg`} className="am-icon am-icon-xs"
                          alt=""/>;
let websocket = null;
export default class Home extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            bottom: false,
            selectedTab: '',
            hidden: false,
            fullScreen: false,
            text: "小羽佳",
            InputValue: "",
            isClick: true,
            visible: false,
            selected: '',
            isLong: false,
            isStop: false,
            newsData: []

        }
    }

    onSelect = (opt) => {
        // console.log(opt.props.value);
        this.setState({
            visible: false,
            selected: opt.props.value,
        });
    };
    handleVisibleChange = (visible) => {
        this.setState({
            visible,
        });
    };


    componentWillMount() {

        // alert("999")
        //this.connectWebSocket();
        this.getList()
    }


    wxData() {
        let url = window.location.href;
        this.$getData("wxUrl", {url: url}).then(res => {
            console.log(res)
            window.wx.config({
                debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                appId: res.data.Data.AppId, // 必填，公众号的唯一标识
                timestamp: res.data.Data.Timestamp, // 必填，生成签名的时间戳
                nonceStr: res.data.Data.Noncestr, // 必填，生成签名的随机串
                signature: res.data.Data.Signature,// 必填，签名，见附录1
                jsApiList: ['onMenuShareTimeline', 'onMenuShareAppMessage', 'startRecord', 'stopRecord', 'onVoiceRecordEnd', 'playVoice', 'stopVoice', 'onVoicePlayEnd', 'translateVoice']
            })
            let _this = this
            window.wx.ready(function () {
                //返回音频的本地ID
                var localId;
                //返回音频的服务器端ID
                var serverId;
                //录音计时,小于指定秒数(minTime = 10)则设置用户未录音
                var startTime, endTime, minTime = 1;

                //开始录音
                $('.start_btn').on('touchstart', function (e) {
                    e.preventDefault();
                    window.navigator.vibrate(100)
                    _this.setState({
                        isLong: true
                    })
                    var $this = $(this);
                    $this.addClass('start_btn_in');
                    startTime = new Date().getTime();
                    //开始录音
                    window.wx.startRecord();
                });
                //***********************************//
                endTime = new Date().getTime();
                //alert((endTime - startTime) / 1000);
                if ((endTime - startTime) / 1000 < minTime) {
                    localId = '';
                    //alert('录音少于' + minTime + '秒，录音失败，请重新录音');
                    Toast.info('说话时间太短', 1);
                }else {
                    //停止录音接口
                    $('.start_btn').on('touchend', function () {
                        var $this = $(this);
                        $this.removeClass('start_btn_in');
                        _this.setState({
                            isLong: false
                        })
                        //停止录音接口
                        window.wx.stopRecord({
                            success: function (res) {
                                localId = res.localId;
                                //播放语音接口
                                window.wx.playVoice({
                                    //需要播放的音频的本地ID，由 stopRecord 或 onVoiceRecordEnd 接口获得
                                    localId: localId
                                });
                                window.wx.translateVoice({
                                    localId:localId, // 需要识别的音频的本地Id，由录音相关接口获得
                                    isShowProgressTips: 1, // 默认为1，显示进度提示
                                    success: function (res) {
                                        //alert(res.translateResult); // 语音识别的结果
                                        _this.setState({
                                            InputValue:res.translateResult
                                        })
                                    },
                                });
                            }
                        });
                    });
                }

                //监听录音自动停止接口
                window.wx.onVoiceRecordEnd({
                    //录音时间超过一分钟没有停止的时候会执行 complete 回调
                    complete: function (res) {
                        localId = res.localId;
                        $('.start_btn').removeClass('start_btn_in');
                        window.wx.stopRecord({
                            success: function (res) {
                            }
                        });
                    }
                });
                //监听语音播放完毕接口
                window.wx.onVoicePlayEnd({
                    //需要下载的音频的服务器端ID，由uploadVoice接口获得
                    serverId: localId,
                    success: function (res) {
                        $('.play_btn').removeClass('stop_btn').text('点我播放');
                        //返回音频的本地ID
                        //localId = res.localId;
                    }
                });
            });

        })
    }

    getList() {
        let res = {
            memberId: 1
        }
        this.$postData("messageLogList", res).then(res => {
            console.log(res.data.data)
            this.setState({
                newsData: res.data.data
            })
        })
    }

    //建立WebSocket连接
    connectWebSocket() {
        //建立webSocket连接
        if ('WebSocket' in window) {
        } else {
            alert('该浏览器不支持websocket');
        }
        //websocket = new WebSocket('ws://xyjapi.xiaoyujia.com/myHandler/id='+Utils.getLocalStorage("openid"));
        //websocket = new WebSocket('ws://xyjapi.xiaoyujia.com/wsSpeech/' + Utils.getLocalStorage("openid"),"chat");
        websocket = new WebSocket('ws://120.77.45.52:9000/wsSpeech/' + JSON.parse(Utils.getLocalStorage("userInfo")).data.open_id);
        //打开webSokcet连接时，回调该函数
        websocket.onopen = function (res) {
            //alert("onpen");
            var postValue = {};
            postValue.id = JSON.parse(Utils.getLocalStorage("userInfo")).data.open_id
            postValue.message = "啦啦啦啦啦啦啦啦啦啦啦啦阿拉啦啦啦啦"
            websocket.send(JSON.stringify(postValue));
        }

        let _this = this
        //关闭webSocket连接时，回调该函数
        websocket.onclose = function (res) {
            //关闭连接
            _this.connectWebSocket()
        }

        //接收信息
        websocket.onmessage = function (data) {
            //alert(data.data);
            _this.setState({
                InputValue: data.data
            })
        }

        websocket.onerror = function (error) {
            //alert(error.type)
        };
    }

    longPress = () => {
        if (this.state.isLong) {
            recorder.stop()
            this.setState({
                isStop: true,
            })
            Toast.loading('识别中...', 0, () => {
                console.log('Load complete !!!');
            });
            //recorder.play();
            this.upload()

            this.setState({
                isLong: false,
            })
        } else {
            recorder.start()
            this.setState({
                isLong: true
            })
        }
    }
    clear = () => {
        this.setState({
            isStop: false,
            InputValue: "",
            bottom:false
        })
        //recorder.destroy()
    }
    //上传
    upload = () => {
        let files = new window.File([recorder.getWAVBlob()], "recorder.wav", {type: "wav/plain"})
        console.log(recorder.getWAVBlob())
        let file = new FileReader();
        this.blobToDataURL(recorder.getWAVBlob())
    }

    blobToDataURL = (blob) => {
        let file = new FileReader();
        file.readAsDataURL(blob);
        let _this = this
        file.onload = function (e) {
            let res = {
                base: e.target.result,
                userId: JSON.parse(Utils.getLocalStorage("userInfo")).data.open_id
            }
            console.log(res)
            _this.$postData("upload", res).then(res => {
                console.log(res)
                // alert(JSON.stringify(res))
                Toast.hide()
                /*  _this.setState({
                      InputValue: res.data.data.text
                  })*/
                // for (let i = 0; i < res.data.data.texts.length; i++) {
                //     let _this1 = _this
                //     setTimeout(function () {
                //         console.log(res.data.data.texts[i])
                //         _this1.setState({
                //             InputValue: res.data.data.texts[i]
                //         })
                //     },1000);
                // }
                // res.data.data.texts.map((text, index) => (
                //     _this.setState({
                //         InputValue:text
                //     })
                // ))

            })
        }
    }
    xuqiu = () => {
        this.setState({
            selectedTab: '',
            text: "小羽佳",
            isClick: true
        })

    }
    handleGetInputValue = (event) => {
        this.setState({
            InputValue: event.target.value
        })
    }
    handle = () => {
        this.refs.son.handlePost(this.state.InputValue) //调用子组件的handlePost方法
        this.clear()
        this.setState({
            bottom: false,
            InputValue: ""
        })
    }


    toggleDrawer = (side, open) => event => {
        console.log(side, open, event)
        this.wxData();
        this.setState({
            isLong: false,
            isTop: false,
            InputValue: "",
        })

        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        this.setState({...this.state, [side]: open});
    };
    fullList = side => (

        <div className="ct"
            role="presentation">

            <TextArea className="textArea"
                      onChange={this.handleGetInputValue}
                      placeholder="我要找个保洁"
                      value={this.state.InputValue}
                      autosize={{minRows: 3, maxRows: 6}}
                      onPressEnter={this.handle}
            />
            <Row gutter={16}>
                <Col className="gutter-row" span={16}>
                    <div className="biaoqing">

                    </div>
                </Col>
                <Col className="gutter-row" span={4}>
                    <div className="biaoqing1">
                        <img className="img5" src={yuyin}></img>

                    </div>
                </Col>
                <Col className="gutter-row" span={4}>
                    <div className="biaoqing">
                        <img src={biaoqing} className="img5">
                        </img>
                    </div>
                </Col>
            </Row>
            <div>
                <Divider dashed/>
            </div>
            <div className="textAreaDiv">
                点击开始说话
            </div>
            <div className="dian">
                <div id="audio-container"></div>
                <Row>
                    <Col span={24}>
                        <div style={{marginTop: "7%"}}><img style={{width: "100px", margin: "0 auto"}}
                                                            src={this.state.isLong == true ? gif : dian}/></div>
                    </Col>

                </Row>
            </div>
            <div style={{marginTop: "8%"}}>

                <div className="qx"  onClick={this.clear}></div>
                <div className="start_btn"  class="start_btn"></div>
                <div className="fs"  onClick={this.handle}></div>
            </div>
        </div>

    );

    render() {
        return (
            <div>
                <NavBar onLeftClick={this.go} className="top"
                        rightContent={
                            <Popover mask
                                     overlayClassName="fortest"
                                     overlayStyle={{color: 'currentColor'}}
                                     visible={this.state.visible}
                                     overlay={[
                                         (<Item key="4" value="scan" icon={myImg('tOtXhkIWzwotgGSeptou')}
                                                data-seed="logId">扫一扫</Item>),
                                         (<Item key="5" value="special" icon={myImg('PKAgAqZWJVNwKsAJSmXd')}
                                                style={{whiteSpace: 'nowrap'}}>添加好友</Item>),
                                         (<Item key="6" value="button ct" icon={myImg('uQIYTFeRrjPELImDRrPt')}>
                                             <span style={{marginRight: 5}}>帮助与反馈</span>
                                         </Item>),
                                     ]}
                                     align={{
                                         overflow: {adjustY: 0, adjustX: 0},
                                         offset: [-10, 0],
                                     }}
                                     onVisibleChange={this.handleVisibleChange}
                                     onSelect={this.onSelect}
                            >
                                <div style={{
                                    height: '100%',
                                    padding: '0 15px',
                                    marginRight: '-15px',
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                                >
                                    <Icon type="ellipsis"/>
                                </div>
                            </Popover>
                        }
                        mode="light">{this.state.text}


                </NavBar>
                <div className="dibu">
                    <Row>
                        <Col span={9}>
                            <div className="leftDiv">
                                <div style={{height: "26px", width: "26px"}}>
                                    <img src={avatar} onClick={() => {
                                        this.setState({
                                            selectedTab: 'newsTab',
                                            text: "消息",
                                            isClick: false
                                        })
                                    }}/>
                                </div>
                                <p>消息</p>
                            </div>
                        </Col>
                        <Col span={6}>
                            <div className="zj">
                                <div style={{height: "55px", width: "55px"}}>
                                    <img src={fabu}
                                         onClick={this.state.isClick == true ? this.toggleDrawer('bottom', true) : this.xuqiu}/>
                                </div>
                                <div><p style={{width: "60px"}}>发布需求</p></div>
                            </div>
                        </Col>
                        <Col span={9}>
                            <div className="rightDiv">
                                <div style={{height: "26px", width: "26px"}}>
                                    <img style={{height: "26px", width: "26px"}} src={me} onClick={() => {
                                        this.setState({
                                            selectedTab: 'me',
                                            text: "我的",
                                            isClick: false
                                        })
                                    }}/>
                                </div>
                                <p>我的</p>
                            </div>

                        </Col>
                    </Row>
                </div>
                {this.state.selectedTab == '' ? <Login history={this.props.history} ref="son"/>
                    : this.state.selectedTab == 'newsTab' ?
                        <News history={this.props.history} newsData={this.state.newsData}/> :
                        <Me history={this.props.history}/>}
                {/*<TabBar*/}
                {/*unselectedTintColor="#949494"*/}
                {/*tintColor="#33A3F4"*/}
                {/*barTintColor="white"*/}
                {/*hidden={this.state.hidden}*/}
                {/*>*/}
                {/*<TabBar.Item*/}
                {/*title="消息"*/}
                {/*key="life"*/}
                {/*icon={*/}
                {/*<div style={{width: '26px', height: '26px',}}>*/}
                {/*<img src={avatar}/>*/}
                {/*</div>*/}
                {/*}*/}
                {/*selectedIcon={<div className="bsx"/>}*/}
                {/*selected={this.state.selectedTab === 'newsTab'}*/}
                {/*badge={1}*/}
                {/*onPress={() => {*/}
                {/*this.setState({*/}
                {/*selectedTab: 'newsTab',*/}
                {/*text: "消息",*/}
                {/*isClick: false*/}
                {/*});*/}
                {/*}}*/}
                {/*data-seed="logId"*/}
                {/*>*/}
                {/*<News history={this.props.history}/>*/}
                {/*</TabBar.Item>*/}
                {/*<TabBar.Item*/}
                {/*icon={*/}
                {/*<div style={{width: '26px', height: '26px',}}>*/}
                {/*<img src={fabu}*/}
                {/*onClick={this.state.isClick == true ? this.toggleDrawer('bottom', true) : ""}/>*/}
                {/*</div>*/}
                {/*}*/}
                {/*selectedIcon={<div onClick={this.state.isClick == true ? this.toggleDrawer('bottom', true) : ""}*/}
                {/*className="fabu"/>}*/}
                {/*title="发布需求"*/}
                {/*key="Koubei"*/}
                {/*selected={this.state.selectedTab === ''}*/}
                {/*onPress={() => {*/}
                {/*this.setState({*/}
                {/*selectedTab: '',*/}
                {/*text: "小羽佳",*/}
                {/*isClick: true*/}
                {/*});*/}
                {/*}}*/}
                {/*data-seed="logId1"*/}
                {/*>*/}
                {/*<Login history={this.props.history} ref="son"/>*/}
                {/*</TabBar.Item>*/}
                {/*<TabBar.Item*/}
                {/*icon={*/}
                {/*<div style={{width: '26px', height: '26px',}}>*/}
                {/*<img src={me}/>*/}
                {/*</div>*/}
                {/*}*/}
                {/*selectedIcon={<div className="bsm"/>}*/}
                {/*title="我的"*/}
                {/*key="Friend"*/}

                {/*selected={this.state.selectedTab === 'me'}*/}
                {/*onPress={() => {*/}
                {/*this.setState({*/}
                {/*selectedTab: 'me',*/}
                {/*text: "我的",*/}
                {/*isClick: false*/}
                {/*});*/}
                {/*}}*/}
                {/*>*/}
                {/*<Me history={this.props.history}/>*/}
                {/*</TabBar.Item>*/}
                {/*</TabBar>*/}
                <Drawer anchor="bottom" open={this.state.bottom} onClose={this.toggleDrawer('bottom', false)}>
                    {this.fullList('bottom')}
                </Drawer>
            </div>
        )
    }
}