import React, {Component} from 'react';
import {NavBar, InputItem, WingBlank, Flex, WhiteSpace} from 'antd-mobile';
import {Divider} from 'antd';
import {createForm} from 'rc-form';
import '../assets/css/more.css';

import rmb from '../assets/imgs/more/rmb.png';


// 通过自定义 moneyKeyboardWrapProps 修复虚拟键盘滚动穿透问题
// https://github.com/ant-design/ant-design-mobile/issues/307
// https://github.com/ant-design/ant-design-mobile/issues/163
const isIPhone = new RegExp('\\biPhone\\b|\\biPod\\b', 'i').test(window.navigator.userAgent);
let moneyKeyboardWrapProps;
if (isIPhone) {
    moneyKeyboardWrapProps = {
        onTouchStart: e => e.preventDefault(),
    };
}


class More extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            type: 'money',
        };
    }

    componentWillMount() {
    }

    render() {
        const {type} = this.state;
        return (

            <div>
                <NavBar
                    mode="light">充值
                </NavBar>
                <div style={{marginLeft: "10px", marginRight: "10px"}}>
                    <div className="moreTop">
                        <div className="textCz">充值金额</div>

                        <div style={{marginTop: "0.5rem"}}>
                            <InputItem autoFocus
                                       type={type}
                                       clear
                                       moneyKeyboardAlign="left"
                                       moneyKeyboardWrapProps={moneyKeyboardWrapProps}
                            >
                                <img style={{width: "32px", height: "40px"}} src={rmb}/>
                            </InputItem>
                        </div>
                        <WingBlank>
                            <div>
                                <Divider/>
                            </div>
                        </WingBlank>

                        <WingBlank size="lg">
                            <Flex justify="center">
                                <Flex.Item>
                                    <div className="morejq">500</div>
                                </Flex.Item>
                                <Flex.Item>
                                    <div className="morejq">1000</div>
                                </Flex.Item>
                                <Flex.Item>
                                    <div className="morejq">2000</div>
                                </Flex.Item>
                                <Flex.Item>
                                    <div className="morejq">3000</div>
                                </Flex.Item>
                            </Flex>
                            <WhiteSpace size="xl"/>
                            <Flex justify="center">
                                <Flex.Item>
                                    <div className="morejq">3500</div>
                                </Flex.Item>
                                <Flex.Item>
                                    <div className="morejq">4000</div>
                                </Flex.Item>
                                <Flex.Item>
                                    <div className="morejq">5000</div>
                                </Flex.Item>
                                <Flex.Item>
                                    <div className="morejq">6000</div>
                                </Flex.Item>
                            </Flex>
                        </WingBlank>


                    </div>
                </div>


                <div className="moreCz">

                </div>
            </div>
        )
    }
}

export default More