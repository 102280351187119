import React from "react";
import {Icon, List, NavBar, Badge} from "antd-mobile";
import tx from "../assets/imgs/news/tx.png";
import xdd from "../assets/imgs/news/xdd.jpg";
import mzd from "../assets/imgs/news/mzd.jpg";

import '../assets/css/news.css';
import '../assets/css/login.css';
import Utils from "../util/utils";

const Item = List.Item;
const Brief = Item.Brief;

class News extends React.Component {
    constructor(props) {
        console.log(props)
        super(props);
        this.state = {
            data: this.props.newsData
        }
    }

    componentWillMount() {
    }



    render() {
        return (
            <div className="tap">
                <div>
                    <List>
                        {this.state.data.map((data, index) => (
                            <Badge text={data.totalRead} className="badge" key={index}>
                                <Item activeStyle={{backgroundColor: "#e3e3e3"}}
                                      extra={data.messageLogDetails.createTime} align="top"
                                      thumb={data.employee.headPortrait}
                                      multipleLine
                                      onClick={() => {
                                          console.log(this.props)
                                          this.props.history.push("/order")

                                      }}>
                                    {data.employee.realName}
                                    <Brief>{data.messageLogDetails.content}</Brief>
                                </Item>
                            </Badge>
                        ))}
                    </List>
                </div>

            </div>
        )
    }
}

export default News