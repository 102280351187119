import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import Router from './routers';  // 默认查找index.js
import App from './App.js';
import * as serviceWorker from './serviceWorker';
import g from './util/global.js'
import GoEasy from 'goeasy';

import createHistory from 'history/createBrowserHistory'
import './assets/css/index.css';
// 1: 引入dva
import dva from 'dva';
// 2: 创建dva的实例app对

g.goEasy = new GoEasy({
    host:'singapore.goeasy.io',//应用所在的区域地址，杭州：hangzhou.goeasy.io，新加坡：singapore.goeasy.io
    appkey: "BC-d4e00cc01c754731aaed4eec7f2127a7",//替换为您的应用appkey
    onConnected: function() {
        console.log('连接成功！')
    },
    onDisconnected: function() {
        console.log('连接断开！')
    },
    onConnectFailed: function(error) {
        console.log(error)
        console.log('连接失败或错误！')
    }
});
let app = new dva({
   history: createHistory()
});
// 3: 配置路由 app.router(fn);
app.router(Router);
// 4: app.use();  //安装插件
// 5: 注册模块app.model({});  {state,effects,reducers}
// app.model(modelIndex);
//app.model(App);
// 5.5: 卸载模块 app.unmodel();
// 6: app.start('#root')
app.start('#root');
//ReactDOM.render(<App/>, document.getElementById('root'));
//serviceWorker.unregister();


